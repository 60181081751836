import React from 'react';
import { Route } from 'react-router-dom';

const AppRoute = ({
                      component: Component,
                      layout: Layout,
                      selecting,
                      setSelecting,
                      texts,
                      ...rest
                  }) => {

    Layout = (Layout === undefined) ? props => (<>{props.children}</>) : Layout;

    return (
        <Route
            {...rest}

            render={props => (

                <Layout selecting={selecting} setSelecting={setSelecting} texts={texts}>
                    <Component {...props} selecting={selecting} setSelecting={setSelecting} texts={texts}/>
                </Layout>
            )} />
    );
}

export default AppRoute;