import React, {useEffect, useRef, useState} from 'react';

import './droping.scss'
import { Link as ScrollLink } from 'react-scroll';
import Logo from "../../layout/partials/Logo";
import useClickOutside from "../../../hooks/useClickOutside/useClickOutside";

const ScrollButton = ({checked , texts}) =>{

    const [styleX, setStyleX] = useState('-10%')
    useEffect(() => {
        setTimeout(() => {
            setStyleX('2%')
        },1000)
    },[])


    const [visible, setVisible] = useState(false)
    const [clickLogo , setClickLogo] = useState(false)
    const modalRef = useRef()
    useClickOutside(modalRef, () => {
        if (clickLogo) setClickLogo(false)
    })

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 700){
            setVisible(true)
        }
        else if (scrolled <= 950){
            setTimeout(() => {
                setVisible(false)
            },0)
        }
    };
    window.addEventListener('scroll', toggleVisible);

    const checkLanguage = () => {
       if(checked === 1)  {
           return {
               HOMEPAGE : 'Գլխավոր էջ',
               SERVICES: texts.arm.headerNavigation.SERVICES,
               ABOUT : texts.arm.headerNavigation.ABOUT,
               PARTNERS : texts.arm.headerNavigation.PARTNERS,
               CONTACTS : texts.arm.headerNavigation.CONTACTS,
               CALCULATOR : texts.arm.headerNavigation.CALCULATOR,
           }
       }
        if(checked === 3)  {
            return {
                HOMEPAGE : 'Home page',
                SERVICES: texts.eng.headerNavigation.SERVICES,
                ABOUT : texts.eng.headerNavigation.ABOUT,
                PARTNERS : texts.eng.headerNavigation.PARTNERS,
                CONTACTS : texts.eng.headerNavigation.CONTACTS,
                CALCULATOR : texts.eng.headerNavigation.CALCULATOR,
            }
        }
        if(checked === 2)  {
            return {
                HOMEPAGE : 'Главная',
                SERVICES: texts.rus.headerNavigation.SERVICES,
                ABOUT : texts.rus.headerNavigation.ABOUT,
                PARTNERS : texts.rus.headerNavigation.PARTNERS,
                CONTACTS : texts.rus.headerNavigation.CONTACTS,
                CALCULATOR : texts.rus.headerNavigation.CALCULATOR,
            }
        }
    }

    return (
        // style={{display: visible ? 'inline' : 'none'}}

            <div
                className="site-nav dropdown hover  heartBeat logo-w"
                onClick={() => setClickLogo(!clickLogo)}
                ref={modalRef}
            >
                <ul
                    style={{left: styleX}}
                    id="main"
                    className={` menu animatedd ${visible ? 'fadeInLeft' : 'fadeOutLeft'} logo-w`}
                >
                    <li>
                        <Logo/>
                        <ul className="sub-menu" style={clickLogo ? {
                            opacity: 1,
                            visibility: 'visible',
                            transform: 'scaleY(1)',
                        } : null}>
                            <div  >
                                <li  > <ScrollLink onClick={() => setClickLogo(!clickLogo)} to="start" spy={true} smooth={true} >{checkLanguage(checked).HOMEPAGE}</ScrollLink></li>
                                <li> <ScrollLink onClick={() => setClickLogo(!clickLogo)} to="service" spy={true} smooth={true} >{checkLanguage(checked).SERVICES}</ScrollLink></li>
                                <li> <ScrollLink onClick={() => setClickLogo(!clickLogo)} to="friends" spy={true} smooth={true}>{checkLanguage(checked).PARTNERS}</ScrollLink></li>
                                <li> <ScrollLink onClick={() => setClickLogo(!clickLogo)} to="about" spy={true} smooth={true}>{checkLanguage(checked).ABOUT}</ScrollLink></li>
                                <li>

                                    <ScrollLink onClick={() => setClickLogo(!clickLogo)} to="calc"  spy={true} smooth={true} > <span><img  className='i-calc'  alt='calc' src={require('../../../assets/images/flags/Untitled-1.png').default}/></span> {checkLanguage(checked).CALCULATOR} </ScrollLink></li>
                                <li style={{borderRadius:'0 0 12px 12px'}}

                                > <ScrollLink onClick={() => setClickLogo(!clickLogo)} to="contact" spy={true} smooth={true}>{checkLanguage(checked).CONTACTS}</ScrollLink></li>
                            </div>
                        </ul>
                    </li>

                </ul>
            </div>


    );
}

export default ScrollButton;