import React, {useEffect, useRef, useState} from "react";
import './lang.scss'

import arm from '../../assets/images/flags/am.svg'
import ru from '../../assets/images/flags/ru.svg'
import gb from '../../assets/images/flags/gb.svg'
import useClickOutside from "../../hooks/useClickOutside/useClickOutside";

const data = [
    {id: 1, label: "հայ",flag : arm },
    {id: 2, label: "рус" ,flag : ru},
    {id: 3, label: "eng" ,flag : gb},
];

const LanguageSelect = ({selecting,setSelecting}) => {

    const [styleX, setStyleX] = useState(-500)

    useEffect(() => {
        setTimeout(() => {
            setStyleX(0)
        },1000)
    },[])


    const [isOpen, setOpen] = useState(false);
    const toggleDropdown = () => setOpen(!isOpen);
    const handleItemClick = (id) => {
        setSelecting(id);
        setOpen(false)
    }

    const findFlag = () => {
        let x = data.find(item => item.id === selecting).flag
        return  <img className='flag-i'  alt='flag' src={x}/>
    }

    const modalRef = useRef()
    useClickOutside(modalRef, () => {
        if (isOpen) setOpen(false)
    })

    return (

        <div className='dropdown' ref={modalRef}>
            <div className='dropdown-header' onClick={toggleDropdown}>
                {selecting ? findFlag() : "Select your destination"}
                <i className={`fa fa-chevron-right icon ${isOpen && "open"}`} style={{fontSize: 8 ,color:'#FFC355',marginRight: 20}}/>
            </div>
            <div  className={`dropdown-body fadeInLeft animated ${ isOpen ? 'open' : 'fadeOutLeft animatedd' }`}>
                {data.map(item => (
                    <div style={{marginLeft: styleX}} key={item.label} className="dropdown-item fadeInLeft animated" onClick={e => {

                        handleItemClick(+e.target.id);

                    }} id={item.id}>
                        <span id={item.id} className={`dropdown-item-dot ${item.id === selecting && 'selected'}`}>✓ </span>
                        <img className='flag-i' id={item.id} alt='flag' src={item.flag}  />
                        <span id={item.id} style={{margin:' -1px 0px 0px 6px',color: '#e8e8e8'}}> {item.label}</span>

                    </div>
                ))}
            </div>
        </div>
    )
};

export default LanguageSelect;