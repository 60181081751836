import React from 'react';
import Typewriter from 'typewriter-effect';

const TypingTextService = ({checked, inWork, inHide}) =>{



    return (
        <div className={`typing-textH-Our ${inWork ? 'in-wrk d-nonem ' : 'typing-textH-Our-mob'} `} style={{textAlign:"center"}}>
            {
                checked === 1 ? (
                    <Typewriter
                        options={{

                            // autoStart: true,
                            loop: true,
                        }}
                        onInit={(typewriter) => {

                            typewriter.changeDelay(70)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.8rem; line-height: 13px;">Այսօր արևային կայան կառուցելը երազանք չէ:`)
                                .changeDelay(50)
                                .pauseFor(8000)
                                .deleteAll(30)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.7rem; line-height: 13px;"> Կառուցիր քո կայանը և մոռացիր էներգետիկ ծախսերի մասին:</span>`)
                                .pauseFor(8000)
                                .deleteAll(30)

                                .start();
                        }}
                    />
                ) : null
            }
            {
                checked === 3 ? (
                    <Typewriter
                        options={{

                            // autoStart: true,
                            loop: true,
                        }}
                        onInit={(typewriter) => {

                            typewriter.changeDelay(70)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.9rem; line-height: 13px;">Building a solar station today is not a dream.`)
                                .changeDelay(50)
                                .pauseFor(8000)
                                .deleteAll(30)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.9rem; line-height: 13px;"> Build your station and forget about energy costs.</span>`)
                                .pauseFor(8000)
                                .deleteAll(30)

                                .start();
                        }}
                    />
                ) : null
            }
            {
                checked === 2 ? (
                    <Typewriter
                        options={{

                            // autoStart: true,
                            loop: true,
                        }}
                        onInit={(typewriter) => {

                            typewriter.changeDelay(70)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.9rem; line-height: 13px;">Строительство солнечной станции сегодня - это не мечта.`)
                                .changeDelay(50)
                                .pauseFor(8000)
                                .deleteAll(30)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.9rem; line-height: 13px;"> Постройте свою станцию и забудьте о расходах на электроэнергию.</span>`)
                                .pauseFor(8000)
                                .deleteAll(30)

                                .start();
                        }}
                    />
                ) : null
            }
        </div>
    );
}

export default TypingTextService
