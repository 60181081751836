import React, {Suspense ,lazy} from 'react';
import TypingTextService from "../../elements/typeing/TypingTextService";
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from "@material-ui/core/styles";
import pMinDelay from "p-min-delay";

const WorksSlider = lazy(() => pMinDelay(import('./SliderCarusel'),850))

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
            backgroundColor: '#FFC355'

        },
        padding: '60px 20px 0 20px',
    }
}));

const OurWorkers = ({checked}) => {
    const classes = useStyles();
    return (
        <Suspense fallback={
            <div className={classes.root} style={  {width: '100%', marginTop: 148}}>
                <LinearProgress />
                <LinearProgress  />
            </div>
        }>
            <div

                style={{
                    height: 545,
                    borderRadius:'0px 0px 20px 20px',
                    border:'5px solid white',
                    position:'relative',
                    overflow: 'hidden',
                    backgroundColor: '#f2f3f4',
                    margin: '0px -1px 0px -1px',
                    display:'flex'
                }} className='fadeIn animated team-cont '>

                <div className='d-nonem '>
                    <div  className=' story-contt wide-h  '   style={{width:350,marginTop:30}}>
                        <div className='pic-cont animatedd fadeInLeft'>
                        <img
                            className='animatedd slideInLeft   pic-1'
                            alt='workers'
                            src={require('../../../assets/images/workers/1.jpg').default}
                            // style={{position:"absolute",width:642, bottom:-21,right:-156}}
                        />
                        </div>
                        <div className='pic-cont animatedd fadeInDown'>
                        <img
                            className='animatedd slideInLeft   pic-2'
                            alt='workers'
                            src={require('../../../assets/images/workers/7.jpg').default}
                            // style={{position:"absolute",width:642, bottom:-21,right:-156}}
                        />
                        </div>
                    </div>
                </div>
                <div className='d-cars'>
                    <WorksSlider/>
                    <TypingTextService service={true} checked={checked} inWork inHide/>
                </div>

            </div>
        </Suspense>
    );
};

export default OurWorkers;

