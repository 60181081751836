import React from 'react';
import './card.scss'

const Card = ({img,title,text,author = null }) =>{
    return(
        <div className='cardOf '>
            <img src={img} alt='img'/>
            <div className="card-body">
                <p>{title}</p>
                <hr/>
                <p>{text}</p>
                <h5>{author}</h5>
            </div>
        </div>
    )
}

export default Card