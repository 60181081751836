export const appTexts = {
    arm:{
        headerNavigation :{
            SERVICES:'ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐ',
            ABOUT:'ՄԵՐ ՄԱՍԻՆ',
            CALCULATOR:'ՀԱՇՎԻՉ',
            CONTACTS:'ԿԱՊ',
            PARTNERS:'ԳՈՐԾԸՆԿԵՐՆԵՐ'
        },
        serviceTexts:{
            CUSTOMER_ONE:'1. Հաճախորդի տարածքի ուսումնասիրություն մասնագետի կողմից:',
            CUSTOMER_TWO:'2. Հաճախորդին լավագույն լուծման առաջարկների ներկայացում:',
            PROJECT_ONE:'1. Նախագծման աշխատանքի իրականացում ըստ հաճախորդի ցանկության:',
            PROJECT_TWO:'2. Ապառիկի դեպքում փաստաթղթաշրջանառության օժանդակություն:',
            SUNNY_ONE:'1. Արևային կայանի կառուցապատում և Արևային պանելների, ինվերտորների, կոնստրուկցիաների և հաղորդալարերի մոնտաժային աշխատանքներ:',
            SUNNY_TWO:'2. Արևային կայանի միացում էլեկտրաէներգետիկական համակարգին:',
            CLEAN_ONE:'1. Պանելների լվացում, ինվերտորների աշխատանքների հսկողություն:',
            CLEAN_TWO:'2. Մոնիթորիգի իրականացում և անհրաժեշտության դեպքում հաշվետվությունների ներկայացում հաճախորդներին:',

        },
        whyChoose: {
            FIRST_TEXT: 'Մեր օրերում թերևս ամենաբարդ հարցը՝ «Ինչու՞ ընտրել մեզ»։ Մեր արտադրական պրոցեսը համապատասխանում է միջազգային չափանիշներին, ինչը թույլ է տալիս գոհացնել նույնիսկ ամենաքմահաճ հաճախորդին ` առաջարկելով բարձր որակ և մատչելի գին: Power Energy առաջարկում է արևային համակարգերի տեղադրման շահավետ ֆինանսական պայմաններ: Մեր ընկերությունը մշտապես պատրաստ է  գտնել լուծումներ իր հաճախորդներին աջակցելու համար: Ապառիկ վաճառքի համար համագործակցում ենք AMERIABANK - ի և Global Credit վարկային կազմակերպության հետ: Փոխշահավետ գործընկերային հարաբերությունները մեր ընկերությանը հնարավորություն են ընձեռում հաճախորդներին առաջարկել նվազագույն գներ և միաժամանակ հուսալի լուծումներ, ինչպես նաև տրամադրել ռեալ ու իրատեսական երաշխիքներ՝ 15-20 տարի արտադրողականության , 25-30 տարի գործարանային երաշխիք, իսկ յուրաքանչյուր հաճախորդի տրամադրում ենք անհատական մոտեցում ՝ առաջարկելով անվճար խորհրդատվություն մեր հմուտ մասնագետների կողմից:',
            SECOND_TEXT: 'Մեգահզոր կայանների կառուցման մեծ փորձ Լավագույն գին/որակ հարաբերակցություն Երաշխիքային  և հետերաշխիքային սպասարկման  բարձր որակ Արտադրանքի մշտական մոնիթորինգ եվ վերլուծություն Փորձառու եվ որակավորված թիմ, բարձրակարգ մասնագետներ Մեծ ծավալով պատվերների իրականացում կարճ ժամկետներում'
        },
        ourPartners :{
            sunFriends:{
                nav:'Արեվային գործընկերներ',
                jinko:'Jinko Solar ընկերությունը հանդիսանում է աշխարհում արևային պանելներ արտադրող խոշոր կազմակերպություն: Իր գործունեությունն սկսել է 2006 թվականին: Ընկերությունն արտադրում է արևային պանելներ և վաճառում միջազգային կազմակերպություններին հետևյալ երկրներում ՝ Ճապոնիա, ԱՄՆ, Գերմանիա, Հնդկաստան, Մեքսիկա, Բրազիլիա, Իտալիա, Ֆրանսիա, Իսպանիա, Բելգիա և այլն: Jinko Solar-ը ունի 9 արտադրական մասնճյուղեր ամբողջ աշխարհում:',
                huawei:'Աշխարհում հայտնի HUAWEI կազմակերպությունը հիմնադրվել է 1987 թվականին: Այն հանդիսանում է ՏՀՏ ենթակառուցվածքների համաշխարհային առաջատար մատակարարը: HUAWEI արտադրության ինվերտորները իրենց որակական հատկանիշներով ու արդյունավետությամբ առաջատարներից են արևային էներգաարտադրության բնագավառում։ Գործում է ավելի քան 170 երկրներում և տարածաշրջաններում՝ սպասարկելով ավելի քան երեք միլիարդ մարդու աշխարհում:'
            },
            sunBanks:{
                nav:'Ֆինանսական Գործընկերներ',
                ameriaBank:'«Ամերիաբանկ» ՓԲԸ-ն ունիվերսալ բանկ է, որը մատուցում է կորպորատիվ, ներդրումային և մանրածախ բանկային ծառայություններ` տրամադրելով բանկային լուծումների ամբողջական փաթեթ: Ամերիաբանկն առաջարկում է միայն անհատականացված ֆինանսաբանկային լուծումներ կերտելով Ձեր բիզնեսի և կյանքի բարելավման ուղիները ֆինանսների տեղաբաշխման և հայթայթման գործում` ինչպես ավանդական, այնպես էլ այլընտրանքային և արդիական բանկային տեխնոլոգիաների միջոցով:',
                globalCredit:'Ընկերության գործունեության հիմնական ռազմավարությունը նպատակաուղղված է ակտիվ վարկային քաղաքականության վարմանը: Վերջինս իր դրսևորումներն է ստացել վարկային պրոդուկտների առավել լայն տեսականու, ինչպես նաև վարկերի տրամադրման առավել մատչելի պայմանների առաջարկմամբ, վարկերի տրամադրման ու վարկային ծառայությունների մատուցման մասնագիտական մոտեցմամբ, հաճախորդների սպասարկման որակի բարձրացմամբ և այլն:'
            }
        },
        calculator: {
            title:'էլեկտրաէներգիայի տարեկան սպառում Հաշվարկը',
            optionLeftSide: {
                calcWithMoney: 'Հաշվարկ գումարով',
                calcIn_kWh:'Հաշվարկ կՎտ/ժամով',
                powerCalc:'Հաշվարկ Հզորությամբ',
                bottomText: 'ՀԱՇՎԵԼ'
            },
            optionRightSide: {
                systemPower: 'Համակարգի հզորություն',
                systemCost: 'Համակարգի արժեք',
                annualSysProd: 'Համակարգի տարեկան արտադրողականություն',
                annualSavings: 'Տարեկան խնայողություն',
                redemptionPeriod: 'Հետգնման ժամկետ'
            },
            optionDetails : {
                kW:' կՎտ',
                year: ' տարի',
                kWh: 'կՎտ/ժամ'
            }

        }
    },
    eng:{
        headerNavigation :{
            SERVICES:'SERVICES',
            ABOUT:'ABOUT',
            CALCULATOR:'CALCULATOR',
            CONTACTS:'CONTACTS',
            PARTNERS:'PARTNERS'
        },
        serviceTexts:{
            CUSTOMER_ONE:'1. Customer area survey by a specialist.',
            CUSTOMER_TWO:'2. Submitting the best solution proposals to the customer.',
            PROJECT_ONE:'1. Carrying out design work according to the customers wish.',
            PROJECT_TWO:'2. Documents accompanying the loan.',
            SUNNY_ONE:'1. Construction of solar station and Installation of solar panels, inverters, structures and Wires.',
            SUNNY_TWO:'2. Connection of a solar station to the power system.',
            CLEAN_ONE:'1. Panel washing, inverter operation control.',
            CLEAN_TWO:'2. Submitting reports to customers in case of monitoring.',
        },
        whyChoose: {
            FIRST_TEXT: 'The most difficult question today is "Why choose us?" Our production process meets international standards, which allows us to satisfy even the most demanding customer by offering high quality at an affordable price. Power Energy offers favorable financial conditions for the installation of solar systems. Our company is always ready to find solutions to support its customers. We cooperate with AMERIABANK and Global Credit credit organization for credit sales. Mutually beneficial partnerships allow our company to offer customers the lowest prices, while providing reliable solutions, such as providing real and realistic guarantees - 15-20 years of productivity, 25-30 years of factory warranty, and we provide each customer with an individual approach, offering free consultation. by skilled professionals.',
            SECOND_TEXT: 'Extensive experience in building mega power plants Best price / quality ratio High quality warranty and post-warranty service Continuous product monitoring and analysis Experienced and qualified team, highly qualified specialists Execution of large volumes of orders in a short time'
        },
        ourPartners :{
            sunFriends:{
                nav:'Solar Partners',
                jinko:'Jinko Solar is the world\'s largest manufacturer of solar panels. It started its activity in 2006. The company produces blood panels and sells them to international organizations in the following countries: Japan, USA, Germany, India, Mexico, Brazil, Italy, France, Spain, Belgium, etc. Jinko Solar has 9 manufacturing branches worldwide.',
                huawei:'The world-famous HUAWEI company was founded in 1987. It is the world\'s leading provider of ICT infrastructure. HUAWEI inverters are one of the leaders in the field of solar energy production with their quality features and efficiency. It operates in more than 170 countries and regions, serving more than three billion people worldwide.'
            },
            sunBanks:{
                nav:'Financial Partners',
                ameriaBank:'Ameriabank CJSC is a universal bank, which provides corporate, investment and retail banking services, providing a complete package of banking solutions. Ameriabank offers only personalized financial and banking solutions, creating ways to improve your business and life in the allocation and acquisition of finances through both traditional and alternative-modern banking technologies.',
                globalCredit:'The main strategy of the company is aimed at pursuing an active credit policy. The latter has received its output from a wider range of credit products, such as offering more affordable lending terms, a professional approach to lending and providing credit services, improving the quality of customer service, and more.'
            }
        },
        calculator: {
            title:'Annual electricity consumption Calculation',
            optionLeftSide: {
                calcWithMoney: 'Calculation with money',
                calcIn_kWh:'Calculation in kWh',
                powerCalc:'Power Calculation',
                bottomText: 'CALCULATE'
            },
            optionRightSide: {
                systemPower: 'System power',
                systemCost: 'System cost',
                annualSysProd: 'Annual system productivity',
                annualSavings: 'Annual savings',
                redemptionPeriod: 'Redemption period'
            },
            optionDetails : {
                kW:' kW',
                year: ' year',
                kWh: 'kW/Hour'
            }

        }
    },
    rus:{
        headerNavigation :{
            SERVICES:'УСЛУГИ',
            ABOUT:'О НАС',
            CALCULATOR:'КАЛЬКУЛЯТОР',
            CONTACTS:'КОНТАКТЫ',
            PARTNERS:'ПАРТНЕРЫ'
        },
        serviceTexts:{
            CUSTOMER_ONE:'1. Обследование клиентской зоны специалистом.',
            CUSTOMER_TWO:'2. Отправка заказчику лучших предложений решения.',
            PROJECT_ONE:'1. Выполнение проектных работ по желанию заказчика.',
            PROJECT_TWO:'2. Сопровождение документов в случае кредита.',
            SUNNY_ONE:'1. Строительство солнечных станций и установка солнечных панелей, инверторов, конструкций и проводов.',
            SUNNY_TWO:'2. Подключение солнечной станции к электросети.',
            CLEAN_ONE:'1. Чистка панелей, контроль работы инвертора.',
            CLEAN_TWO:'2. Отправка отчетов клиентам в случае мониторинга.',
        },
        whyChoose: {
            FIRST_TEXT: 'Самый сложный вопрос сегодня - «Почему выбрали нас?» Наш производственный процесс соответствует международным стандартам, что позволяет нам удовлетворить даже самого капризного покупателя, предлагая высокое качество по доступной цене. Power Energy предлагает выгодные финансовые условия для установки солнечных систем. Наша компания всегда готова найти решения для поддержки своих клиентов. Мы сотрудничаем с АМЕРИАБАНК и Глобальной кредитной организацией по продажам в кредит. Взаимовыгодные партнерские отношения позволяют нашей компании предлагать клиентам самые низкие цены, обеспечивая при этом надежные решения, такие как предоставление реальных и реалистичных гарантий - 15-20 лет производительности, 25-30 лет заводской гарантии, и мы обеспечиваем индивидуальный подход к каждому клиенту, предлагая бесплатную консультацию опытных профессионалов.',
            SECOND_TEXT:'Большой опыт строительства мегаэлектростанций Лучшее соотношение цены и качества Гарантия высокого качества и послегарантийное обслуживание Постоянный мониторинг и анализ продукции Опытная и квалифицированная команда, высококвалифицированные специалисты Выполнение больших объемов заказов в короткие сроки'
        },
        ourPartners :{
            sunFriends:{
                nav:'Солнечные партнеры',
                jinko:'Jinko Solar - крупнейший в мире производитель солнечных батарей. Свою деятельность начал в 2006 году. Компания производит панели крови, продает их международным организациям в следующих странах: Япония, США, Германия, Индия, Мексика, Бразилия, Италия, Франция, Испания, Бельгия и др. Jinko Solar имеет 9 производственных филиалов по всему миру.',
                huawei:'Всемирно известная компания HUAWEI была основана в 1987 году. Это ведущий в мире поставщик инфраструктуры ИКТ. Инверторы HUAWEI по своим качественным характеристикам и эффективности являются одними из лидеров в области производства солнечной энергии. Он работает более чем в 170 странах и регионах, обслуживая более трех миллиардов человек по всему миру.'
            },
            sunBanks:{
                nav:'Финансовые партнеры',
                ameriaBank:'ЗАО Америабанк - универсальный банк, который предоставляет корпоративные, инвестиционные и розничные банковские услуги, предоставляя полный пакет банковских решений. Америабанк предлагает только персонализированные финансовые и банковские решения, создавая способы улучшить жизнь вашего бизнеса при распределении и привлечении финансовых средств с помощью как традиционных, так и альтернативных современных банковских технологий.',
                globalCredit:'Основная стратегия компании направлена ​​на проведение активной кредитной политики. Последнее нашло свое воплощение в более широком спектре кредитных продуктов, таких как предложение более доступных условий кредитования, профессиональный подход к кредитованию и предоставлению услуг, повышение качества обслуживания клиентов и т. Д.'
            }
        },
        calculator: {
            title:'Расчет годового потребления электроэнергии',
            optionLeftSide: {
                calcWithMoney: 'Расчет в деньгах',
                calcIn_kWh:'Расчет в кВтч',
                powerCalc:'Расчет мощности',
                bottomText: 'РАССЧИТАТЬ'
            },
            optionRightSide: {
                systemPower: 'Мощность системы',
                systemCost: 'Стоимость системы',
                annualSysProd: 'Годовая производительность системы',
                annualSavings: 'Годовая экономия',
                redemptionPeriod: 'Срок погашения'
            },
            optionDetails : {
                kW:' кВт',
                year: ' год',
                kWh: 'кВт/час'
            }

        }
    }
}
