import React from 'react';
import classNames from 'classnames';
import './hero.scss'
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import { Link as ScrollLink } from 'react-scroll';
import ScrollButton from "../elements/ScrollButton/ScrollButton";
import Birds from "../elements/birds/birds";
import FooterSocial from "../layout/partials/FooterSocial";
import TypingText from "../elements/typeing/TypeingTextSocialMedia";


const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const HomePage = ({
                selecting,
                texts,
                className,
                topOuterDivider,
                bottomOuterDivider,
                topDivider,
                bottomDivider,
                hasBgColor,
                invertColor,
                ...props
              }) => {

  // const [videoModalActive, setVideomodalactive] = useState(false);
  //
  // const openModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(true);
  // }
  //
  // const closeModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(false);
  // }

  const outerClasses = classNames(
      'bgpic',
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
  );

  const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
  );

  return (
      <section
          id='start'
          {...props}
          className={outerClasses}
      >
        <Birds/>
        <div className="container-sm padFor padForphone">
          <ScrollButton checked={selecting} texts={texts}/>
          <div className={innerClasses}>
            <div className="hero-content">
              <div className="container-xs">
                <div className='sizeY' style={{display:'flex',marginLeft:190, zIndex:1}}>
                  <div
                      className="reveal-from-bottom animatedd slideInLeft pwSize sCase" data-reveal-delay="350"
                  >
                    <Image
                        src={require('./../../assets/images/1.png').default}
                        className='pwEnergy'
                        width={200}
                        height={200} />
                  </div>
                  <div className="reveal-from-bottom animatedd slideInRight pwSize2 " data-reveal-delay="350">
                    <Image
                        src={require('./../../assets/images/2.png').default}
                        className='pwEnergy'
                        width={200}
                        height={200} />
                  </div>
                </div>

              </div>
              <div className='textStyle textStylePc fadeeIn animatedd'>
                <div
                    className="reveal-from-bottom" data-reveal-delay="1000"
                    style={{marginRight:43, marginTop:'-2%' ,marginLeft:-77}}
                >
                  <Image
                      src={require('./../../assets/images/power.png').default}
                      width={300}
                      height={300} />
                </div>
                <div className="reveal-from-bottom" data-reveal-delay="1000">
                  <Image
                      src={require('./../../assets/images/energy.png').default}

                      width={300}
                      height={300} />
                </div>
              </div>
              <div className="reveal-from-bottom solSys fadeeIn animated" data-reveal-delay="1300" data-reveal-value="0px">
                <Image
                    src={require('./../../assets/images/system.png').default}

                    width={300}
                    height={300} />
              </div>
            </div>

          </div>
          <div>
            <div>
              <img className='arrType' src={require('../../assets/images/arrow2.png').default} alt='arrow'/>
              <TypingText  checked={selecting}/>
              <div className='scroll-downSocial'><FooterSocial/></div>
            </div>
            <ScrollLink  to="service" spy={true} smooth={true}  className="scroll-down" />
          </div>
        </div>
      </section>
  );
}

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

export default HomePage;