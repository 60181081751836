import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import './hero.scss'
import Image from "../elements/Image";
import BackgroundCirclePartner from "../elements/BackgroundCirclePartner";

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const OurPartners = ({
                       checked,
                       texts,
                       className,
                       topOuterDivider,
                       bottomOuterDivider,
                       topDivider,
                       bottomDivider,
                       hasBgColor,
                       invertColor,
                       pushLeft,
                       ...props
                     }) => {

  const outerClasses = classNames(
      'klp-oop',
      'mondial',
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
  );

  const innerClasses = classNames(
      'testimonial-inner section-inner',
      bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
      'space-arr',
      'tiles-wrap',
      pushLeft && 'push-left'
  );
  const checkLanguage = () => {
    if(checked === 1) {
      return {
        title: texts.arm.headerNavigation.PARTNERS

      }
    }
    if(checked === 2) {
      return {
        title: texts.rus.headerNavigation.PARTNERS
      }
    }
    if(checked === 3) {
      return {
        title: texts.eng.headerNavigation.PARTNERS
      }
    }
  }

  const languageTexts = () => {
    if (checked === 1 ) return  texts.arm.ourPartners
    if (checked === 2 ) return  texts.rus.ourPartners
    if (checked === 3 ) return  texts.eng.ourPartners
  }

  const checkTextSize = () => {
    if(checked === 3) return  'p-jin-t jin-eng'
    if(checked === 2) return  'p-jin-t jin-rus'
    if(checked === 1) return  'p-jin-t'
  }
  const checkTextSizeStyles = (c) => {
  // {lineHeight: '15px'}
    if(c === 'huawei' && checked === 2) {
      return {lineHeight: '17px'}
    } else if( c === 'huawei' && checked === 1 ) {
      return {lineHeight: '15px'}
    } else if( c === 'huawei' && checked === 3 ) {
      return {fontSize:'16px',lineHeight: '17px'}
    }

    if(c=== 'ameria' && checked === 2 ) {
      return {fontSize: 12,lineHeight: '17px'}
    } else if(c=== 'ameria' && checked === 1 ) {
      return {fontSize: 12}
    }
     else if(c=== 'ameria' && checked === 3 ) {
     return  {fontSize: 13}
    }

    if(c=== 'global' && checked === 3 ) {
      return {lineHeight: '19px'}
    } else if(c=== 'global' && checked === 1 ) {
      return {lineHeight: '15px'}
    }
    else if(c=== 'global' && checked === 2 ) {
      return  {lineHeight: '19px'}
    }
  }

  const langStyleTitle = () => {
    if(checked === 1) return 'we-arm'
    if(checked === 2) return 'we-next'
    if(checked === 3) return 'we-next'
  }
  return (
      <section

          id='friends'
          {...props}
          className={outerClasses}
          style={{position:'relative'}}
      >
        <div style={{position:"absolute",bottom:-30,zIndex:100}}>
          <div id='hexagon-first-one'/>
          <div style={{position:'relative'}}>
            <img alt='bolt' className='hexCircle  rotating' src={require('./../../assets/images/flags/bolt1.png').default}/>
            <img alt='bolt' className='hexCircleMiddle rotatingBack' src={require('./../../assets/images/flags/bolt2.png').default}/>
            <img alt='bolt' className='hexCircleDown  rotating2' src={require('./../../assets/images/flags/bolt3.png').default}/>
          </div>
        </div>
        <div style={{position:"absolute",bottom:-27,right:-125,zIndex:100}}>
          <div  id='hexagon-first-two'/>
          <div style={{position:'relative'}}>
            <img alt='bolt' className='hexRight  rotating' src={require('./../../assets/images/flags/bolt1.png').default}/>
            <img alt='bolt' className='hexRightMiddle rotatingBack' src={require('./../../assets/images/flags/bolt2.png').default}/>
            <img alt='bolt' className='hexRightDown  rotating2' src={require('./../../assets/images/flags/bolt3.png').default}/>
          </div>
        </div>

        <div className="container" >
          <div className={innerClasses} style={{textAlign:'center',zIndex: 6}}>
            <SectionHeader makeStyle={langStyleTitle(checked)} data={checkLanguage(checked)} className="center-content h-3df" />
            <BackgroundCirclePartner />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-right" data-reveal-delay="200" style={{zIndex:10}}>
                <div className="tiles-item-inner  card-style">
                  <div className="testimonial-item-footer text-xs  mb-0 has-top-divider" style={{display: 'flex',
                    margin: '0 0 20px 0'}}>
                    <span className='f-icon'/>
                    <span className="testimonial-item-name text-color-high">{languageTexts(checked).sunFriends.nav}</span>

                  </div>
                  <div className="testimonial-item-content">
                    <div className='card-partners'>
                      <Image
                          className={'huw-css3'}
                          src={require('./../../assets/images/partiers/jinko.png').default}
                          alt="Features tile icon 01"
                          width={300}
                          height={300} />
                      <p  className={ checkTextSize()}>{languageTexts(checked).sunFriends.jinko}</p>
                    </div>
                    <div className='card-partners'>
                      <Image
                          className={'huw-cs'}
                          src={require('./../../assets/images/partiers/huawei.png').default}
                          alt="Features tile icon 01"
                          width={300}
                          height={300} />
                      <p style={checkTextSizeStyles('huawei')} className={ `${checkTextSize()}  p-huw-t`}>{languageTexts(checked).sunFriends.huawei}</p>
                    </div>
                  </div>

                </div>
              </div>

              <div className="tiles-item reveal-from-left " data-reveal-delay="200" style={{zIndex:10}}>
                <div className="tiles-item-inner card-style">
                  <div className="testimonial-item-footer text-xs  mb-0 has-top-divider" style={{display: 'flex',
                    margin: '0 0 20px 0'}}>
                    <span className='f-icon'/>
                    <span className="testimonial-item-name text-color-high">{languageTexts(checked).sunBanks.nav}</span>
                  </div>
                  <div className="testimonial-item-content">
                    <div className='card-partners'>
                      <Image
                          src={require('./../../assets/images/partiers/Ameriabank.svg').default}
                          alt="Features tile icon 01"
                          className={'p-huw-cont'}
                          width={300}
                          height={300}
                      />
                      <div>
                      <p style={ checkTextSizeStyles('ameria') } className={checkTextSize()}>{languageTexts(checked).sunBanks.ameriaBank}</p>
                      </div>
                    </div>
                    <div className='card-partners'>
                      <Image
                          src={require('./../../assets/images/partiers/global.jpg').default}
                          alt="Features tile icon 01"
                          className={'p-glob-cont'}
                          width={300}
                          height={300}
                      />
                      <p style={checkTextSizeStyles('global')} className={` ${checkTextSize()} p-glob-t ` }>{languageTexts(checked).sunBanks.globalCredit}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <img
            className='d-nonem'
            style={{
              position: 'absolute',
              bottom: '28%',
              zIndex: 5,
              opacity: 0.9,
            }}
            src={require('./../../assets/images/partiers/partners.jpg').default} alt='partner'
        />
      </section>
  );
}

OurPartners.propTypes = propTypes;
OurPartners.defaultProps = defaultProps;

export default OurPartners;