import React from 'react';
import './circle.scss'
const BackgroundCircle = () => {

    return (
        <>
            <div className="main_part_sec">
                <div className={`  mainOur__part_sec `} >

                            <svg xmlns="http://www.w3.org/2000/svg" style={{overflow:'inherit'}} className={`dotted-circle`} width="200" height="200"
                                 overflow="visible">
                                <circle cx="100" cy="100" r="100" fill="none" stroke="#013856" strokeWidth="1"
                                        strokeMiterlimit="6" strokeDasharray="3.921,4.9271"/>
                            </svg>
                </div>
            </div>
            <div className="main_part">
                <div className={`main_nextOur__text-wrapper mainOur__part`} >
                            <svg xmlns="http://www.w3.org/2000/svg" style={{overflow:'inherit'}} className='dotted-circle'  width="200" height="200"
                                 overflow="visible">
                                <circle cx="100" cy="100" r="100" fill="none" stroke="#013856" strokeWidth="1"
                                        strokeMiterlimit="6" strokeDasharray="3.921,4.9271"/>
                            </svg>
                </div>
            </div>
        </>
    );
};

export default BackgroundCircle;