import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import BackgroundCircle from "../elements/BackgroundCircle";
import '../../assets/scss/core/elements/card-move.scss'
import Card from "../card/Card";
import TypingTextService from "../elements/typeing/TypingTextService";
const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const OurServices = ({
                         checked,
                         texts,
                         className,
                         topOuterDivider,
                         bottomOuterDivider,
                         topDivider,
                         bottomDivider,
                         hasBgColor,
                         invertColor,
                         pushLeft,
                         ...props
                       }) => {

  const outerClasses = classNames(
      'bgcont',
      'features-tiles section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
  );

  const innerClasses = classNames(
      'features-tiles-inner section-inner pt-0',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
      'jar',
      'tiles-wrap center-content',
      pushLeft && 'push-left'
  );

  const checkLanguage = () => {
    if(checked === 1) {
      return {
        title: texts.arm.headerNavigation.SERVICES
      }
    }
    if(checked === 2) {
      return {
        title: texts.rus.headerNavigation.SERVICES
      }
    }
    if(checked === 3) {
      return {
        title: texts.eng.headerNavigation.SERVICES
      }
    }
  }


  const checkLanguageCustomer = (mi) => {
    if(mi) {
      if(checked === 1) return   texts.arm.serviceTexts.CUSTOMER_TWO
      if(checked === 2) return   texts.rus.serviceTexts.CUSTOMER_TWO
      if(checked === 3) return   texts.eng.serviceTexts.CUSTOMER_TWO
    } else {
      if(checked === 1) return   texts.arm.serviceTexts.CUSTOMER_ONE
      if(checked === 2) return   texts.rus.serviceTexts.CUSTOMER_ONE
      if(checked === 3) return   texts.eng.serviceTexts.CUSTOMER_ONE
    }
  }

  const checkLanguageProject = (mi) => {
    if(mi) {
      if(checked === 1) return   texts.arm.serviceTexts.PROJECT_TWO
      if(checked === 2) return   texts.rus.serviceTexts.PROJECT_TWO
      if(checked === 3) return   texts.eng.serviceTexts.PROJECT_TWO

    } else {
      if(checked === 1) return   texts.arm.serviceTexts.PROJECT_ONE
      if(checked === 2) return   texts.rus.serviceTexts.PROJECT_ONE
      if(checked === 3) return   texts.eng.serviceTexts.PROJECT_ONE
    }

  }
  const checkLanguageSunny = (mi) => {
    if(mi) {
      if(checked === 1) return   texts.arm.serviceTexts.SUNNY_TWO
      if(checked === 2) return   texts.rus.serviceTexts.SUNNY_TWO
      if(checked === 3) return   texts.eng.serviceTexts.SUNNY_TWO
    } else {

      if(checked === 1) return   texts.arm.serviceTexts.SUNNY_ONE
      if(checked === 2) return   texts.rus.serviceTexts.SUNNY_ONE
      if(checked === 3) return   texts.eng.serviceTexts.SUNNY_ONE
    }

  }
  const checkLanguageClean = (mi) => {
    if(mi) {
      if(checked === 1) return   texts.arm.serviceTexts.CLEAN_TWO
      if(checked === 2) return   texts.rus.serviceTexts.CLEAN_TWO
      if(checked === 3) return   texts.eng.serviceTexts.CLEAN_TWO
    } else {

      if(checked === 1) return   texts.arm.serviceTexts.CLEAN_ONE
      if(checked === 2) return   texts.rus.serviceTexts.CLEAN_ONE
      if(checked === 3) return   texts.eng.serviceTexts.CLEAN_ONE
    }

  }
  const langStyleTitle = () => {
    if(checked === 1) return 'we-arm serv-fo'
    if(checked === 2) return 'we-next'
    if(checked === 3) return 'we-next'
  }

  return (


      <section
          id='service'
          style={{position:'relative',paddingTop:60}}
          {...props}
          className={outerClasses}
      >
        <div style={{position:"absolute",bottom:-30,zIndex:100}}>
          <div id='hexagon-first-one'/>
          <div style={{position:'relative'}}>
            <img alt='bolt' className='hexCircle rotating' src={require('./../../assets/images/flags/bolt1.png').default}/>
            <img alt='bolt' className='hexCircleMiddle rotatingBack' src={require('./../../assets/images/flags/bolt2.png').default}/>
            <img alt='bolt' className='hexCircleDown rotating2' src={require('./../../assets/images/flags/bolt3.png').default}/>
          </div>
        </div>
        <div style={{position:"absolute",bottom:-27,right:-125,zIndex:100}}>
          <div  id='hexagon-first-two'/>
          <div style={{position:'relative'}}>
            <img alt='bolt' className='hexRight rotating' src={require('./../../assets/images/flags/bolt1.png').default}/>
            <img alt='bolt' className='hexRightMiddle rotatingBack' src={require('./../../assets/images/flags/bolt2.png').default}/>
            <img alt='bolt' className='hexRightDown rotating2' src={require('./../../assets/images/flags/bolt3.png').default}/>
          </div>

        </div>
        <BackgroundCircle next={false}/>

        <div className="container" style={{position:'relative', maxWidth:"unset"}}>
          <div className={innerClasses} style={{zIndex:5}}>
            <div style={{paddingBottom:20}}>
              <SectionHeader  makeStyle={langStyleTitle(checked)} texts={texts} data={checkLanguage()} className="center-content" style={{marginTop:130}} checked={checked}/>
              <TypingTextService service={true} checked={checked} />
            </div>
            <div className={tilesClasses} >
              <div className="tiles-item reveal-from-bottom cardsUs" data-reveal-delay="400">
                <Card

                    img={require('./../../assets/images/help.jpg').default}
                    title={checkLanguageCustomer(false)}
                    text={checkLanguageCustomer(true)}

                />
              </div>
              <div className="tiles-item reveal-from-bottom cardsUs" data-reveal-delay="400">
                <Card
                    img={require('./../../assets/images/engy.jpg').default}
                    title={checkLanguageProject(false)}
                    text={checkLanguageProject(true)}

                />
              </div>

              <div style={{display:"flex"}} className='n-flex'>
                <div className="tiles-item reveal-from-bottom cardsUs" data-reveal-delay="400">
                  <Card
                      img={require('./../../assets/images/sun.jpg').default}
                      title={checkLanguageSunny(false)}
                      text={checkLanguageSunny(true)}

                  />
                </div>
                <div className="tiles-item reveal-from-bottom cardsUs" data-reveal-delay="400">
                  <Card
                      img={require('./../../assets/images/clean.jpg').default}
                      title={checkLanguageClean(false)}
                      text={checkLanguageClean(true)}
                  />

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}

OurServices.propTypes = propTypes;
OurServices.defaultProps = defaultProps;

export default OurServices;