import React from 'react';


const StoryParagraph = ({checked}) => {


    return (
        <>
            {
                checked === 1 ? (
                <p className=" size-p text-const">
                    Power<span style={{color: '#ffb836'}}>Energy</span> կազմակերպությունը հիմնադրվել է 2019 թվականին:
                    Մենք զբաղվում ենք արևային կայանների տեղադրմամբ` նախագծումից մինչև կառուցապատում։
                    Հանդիսանում ենք արևային պանելներ արտադրող առաջատար ընկերություններից
                    <strong style={{fontWeight: 500}}> JINKO SOLAR</strong> -ի պաշտոնական ներկայացուցիչը Հայաստանում,
                    իսկ ինվերտորների մասով
                    համագործակցում ենք հանրահայտ <strong style={{fontWeight: 500}}>HUAWEI</strong> Ընկերության հետ։ Մեր
                    ընկերությունը Հայաստանում
                    թերևս միակներից է, որը կառուցել է ժամում 5 ՄՎՏ էներգիա արտադրող արևային կայաններ։
                    Այս կարճ ժամանակահատվածում մենք կարողացել ենք ձեռք բերել կայուն ու հուսալի գործընկերներ,
                    մեր հաճախորդները վստահում են մեզ, քանի որ մենք շեշտադրում ենք հաճախորդին տրամադրվող ծառայությունների
                    որակական հատկանիշները։ Power<span style={{color: '#ffb836'}}>Energy</span> կազմակերպությունը իր
                    մատուցած որակյալ ծառայություններով այս պահին շուկայի առաջատարներից է։
                </p>
            ) : null
            }
            {
                checked === 2 ? (
                    <p className=" size-p text-const">
                        Power<span style={{color: '#ffb836'}}>Energy</span> была основана в 2019 году.
                        Мы занимаемся установкой солнечных станций, от проектирования до строительства.
                        Мы являемся официальным представителем
                        <strong style={{fontWeight: 500}}> JINKO SOLAR</strong> одного из ведущих
                        производителей солнечных панелей в Армении, и сотрудничаем с известной компанией
                         <strong style={{fontWeight: 500}}> HUAWEI</strong> в области инверторов. Наша компания
                        - одна из немногих в Армении, которая построила солнечные электростанции, производящие
                        5 МВт энергии в час. За этот короткий промежуток времени мы смогли обзавестись
                        стабильными и надежными партнерами, наши клиенты нам доверяют, потому что мы делаем
                        упор на качество предоставляемых клиентам услуг. Power<span style={{color: '#ffb836'}}>Energy</span>
                        на данный момент является одним из лидеров рынка по качеству предоставляемых услуг.
                    </p>
                ) : null
            }

            {
                checked === 3 ? (
                    <p className=" size-p text-const">
                        Power<span style={{color: '#ffb836'}}>Energy</span> was founded in 2019. We are engaged
                        in the installation of solar stations, from design to construction. We are the official representative of
                        <strong style={{fontWeight: 500}}> JINKO SOLAR</strong> one of the leading companies
                        producing solar panels in Armenia, and we cooperate with the famous
                        <strong style={{fontWeight: 500}}> HUAWEI</strong> Company in terms of inverters.
                        Our company is one of the only ones in Armenia that has built solar power plants
                        producing 5 MW of energy per hour. In this short period of time we have been able
                        to get stable and reliable partners, our customers trust us, because we emphasize
                        the quality of the services provided to the customer. Power<span style={{color: '#ffb836'}}>Energy</span>
                        is one of the market leaders at the moment with its quality services.
                    </p>
                ) : null
            }

        </>


    )
}


const OurTeam = ({img ,checked}) => {

    return (
        <div

            style={{
                height:'100%',
                borderRadius:'0px 0px 20px 20px',
                border:'5px solid white',
                position:'relative',
                overflow: 'hidden',
                backgroundColor: '#f2f3f4',
                margin: '0px -1px 0px -1px'
            }} className='fadeIn animated team-cont marg-s'>
            <div  className='animatedd slideInLeft story-cont wide-h d-cars'  >
                {<StoryParagraph
                    checked={checked}
                />}
            </div>
            <img
                className='animatedd slideInRight story-c c-hide'
                alt='story'
                src={img}
                style={{position:"absolute",width:642, bottom:-21,right:-156}}
            />

        </div>
    );
};

export default OurTeam;

