import './lighting.css'
import React from 'react'
// import Logo from "../layout/partials/Logo";
// import FooterSocial from "../layout/partials/FooterSocial";

const Lighting = () => {


    return (


            <div>

                <img src={require('./../../assets/images/logo-page.png').default} className='logoPage' alt='logo'/>
            </div>


    )
}

export default Lighting