
import React from 'react';
import Typewriter from 'typewriter-effect';

const TypingTextCalculate = ({checked}) =>{



    return (
        <div className={`typing-textH-cho d-m-co `} style={{textAlign:"center"}}>
            {
                checked === 1 ? (
                    <Typewriter
                        options={{

                            // autoStart: true,
                            loop: true,
                        }}
                        onInit={(typewriter) => {

                            typewriter.changeDelay(70)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.8rem; line-height: 13px;">Ցանկանում ես կառուցել արևային կայան՞`)
                                .changeDelay(50)
                                .pauseFor(8000)
                                .deleteAll(30)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.7rem; line-height: 13px;">Որքան կարժենա արևային կայանի կառուցումը՞</span>`)
                                .pauseFor(8000)
                                .deleteAll(30)

                                .start();
                        }}
                    />
                ) : null
            }
            {
                checked === 3 ? (
                    <Typewriter
                        options={{

                            // autoStart: true,
                            loop: true,
                        }}
                        onInit={(typewriter) => {

                            typewriter.changeDelay(70)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.8rem; line-height: 13px;">Do you want to build a solar station?`)
                                .changeDelay(50)
                                .pauseFor(8000)
                                .deleteAll(30)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.6rem; line-height: 13px;">How much will it cost to build a solar station?</span>`)
                                .pauseFor(8000)
                                .deleteAll(30)

                                .start();
                        }}
                    />
                ) : null
            }
            {
                checked === 2 ? (
                    <Typewriter
                        options={{

                            // autoStart: true,
                            loop: true,
                        }}
                        onInit={(typewriter) => {

                            typewriter.changeDelay(70)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.8rem; line-height: 13px;">Хотите построить солнечную станцию?`)
                                .changeDelay(50)
                                .pauseFor(8000)
                                .deleteAll(30)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.7rem; line-height: 13px;">Сколько будет стоить строительство солнечной станции?</span>`)
                                .pauseFor(8000)
                                .deleteAll(30)

                                .start();
                        }}
                    />
                ) : null
            }
        </div>
    );
}

export default TypingTextCalculate