import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string
  }).isRequired,
  children: PropTypes.node,
  tag: PropTypes.oneOf(['h1', 'h2', 'h3'])
}

const defaultProps = {
  children: null,
  tag: 'h2'
}

const SectionHeader = ({
    checked,
    makeStyle,
    right,
    textWide,
    className,
    data,
    children,
    tag,
    ...props
}) => {

  const classes = classNames(
    'section-header',
    className
  );

  const Component = tag;
  return (
    <>
      {(data.title || data.paragraph) &&
        <div
          {...props}
          className={classes}
          style={right?  {textAlign:'left'} : null}
        >
          <div className="container-xs"  style={textWide?  {maxWidth:"initial"} : null}>
            {data.title &&
              <Component className={
                classNames(
                    makeStyle,
                  'mt-0',
                  data.paragraph ? 'mb-50' : 'mb-0'
                )} style={  {color:'#013856' }}>{data.title.toUpperCase()}</Component>
            }
            {data.paragraph &&
              <p
                  className="m-0 "
                  style={textWide?
                      {fontFamily:'system-ui',
                        textAlign:'justify',
                        border:'1px solid #0c5c87',
                        boxShadow:'2px 2px 4px 0px rgb(10 12 15 / 36%)' ,
                        borderRadius:6,
                        padding:16,
                        backgroundColor: 'rgba(0,0,0, 0.05)'
                      }
                      : null}>{data.paragraph}</p>
            }
          </div>
        </div>
      }
    </>
  );
}

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
