import React from 'react';
import Typewriter from 'typewriter-effect';

const TypingTextSocialMedia = ({checked}) =>{



    return (
        <div className='typing-textH'>
            {
                checked === 1 ? (
                    <Typewriter
                        options={{

                            // autoStart: true,
                            loop: true,
                        }}
                        onInit={(typewriter) => {
                            typewriter.changeDelay(70)
                            typewriter.typeString(`<span style="color: #fff; font-size: 0.8rem;">Հետևիր մեզ <span style="color: #4267B2"><strong>Facebook</strong></span>-ում:</span>`)
                                .changeDelay(50)
                                .pauseFor(3000)
                                .deleteChars(13)
                            typewriter.typeString(`<span style="color: #fff; font-size: 0.8rem;"><span style="color: #cd486b"><strong>Instagram</strong></span>-ում:</span>`)
                                .pauseFor(3000)

                                .start();
                        }}
                    />
                ) : null
            }
            {
                checked === 3 ? (
                    <Typewriter
                        options={{

                            // autoStart: true,
                            loop: true,
                        }}
                        onInit={(typewriter) => {
                            typewriter.changeDelay(70)
                            typewriter.typeString(`<span style="color: #fff; font-size: 0.8rem;">Follow us in <span style="color: #4267B2"><strong>Facebook</strong></span>.</span>`)
                                .changeDelay(50)
                                .pauseFor(3000)
                                .deleteChars(9)
                            typewriter.typeString(`<span style="color: #fff; font-size: 0.8rem;"><span style="color: #cd486b"><strong>Instagram</strong></span>.</span>`)
                                .pauseFor(3000)

                                .start();
                        }}
                    />
                ) : null
            }
            {
                checked === 2 ? (
                    <Typewriter
                        options={{

                            // autoStart: true,
                            loop: true,
                        }}
                        onInit={(typewriter) => {
                            typewriter.changeDelay(70)
                            typewriter.typeString(`<span style="color: #fff; font-size: 0.8rem;">Подписывайтесь на нас в <span style="color: #4267B2"><strong>Facebook</strong></span>.</span>`)
                                .changeDelay(50)
                                .pauseFor(3000)
                                .deleteChars(9)
                            typewriter.typeString(`<span style="color: #fff; font-size: 0.8rem;"><span style="color: #cd486b"><strong>Instagram</strong></span>.</span>`)
                                .pauseFor(3000)

                                .start();
                        }}
                    />
                ) : null
            }
        </div>
    );
}

export default TypingTextSocialMedia