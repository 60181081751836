import React from 'react';
// import sections
import HomePage from '../components/sections/HomePage.js';
import OurServices from '../components/sections/OurServices';
import OboutUs from '../components/sections/OboutUs/OboutUs';
import Calculate from "../components/sections/Calculate";
import OurPartners from "../components/sections/OurPartners.js";

const Home = ({selecting,texts}) => {

    return (
        <>
            <HomePage
                selecting={selecting}
                texts={texts}
                className="illustration-section-01"
            />
            <OurServices
                checked={selecting}
                texts={texts}
            />
            <OboutUs
                invertMobile
                topDivider
                imageFill
                className="illustration-section-02"
                checked={selecting}
                texts={texts}
            />
            <OurPartners topDivider checked={selecting}  texts={texts}/>
            <Calculate checked={selecting}  texts={texts}/>
        </>
    );
}

export  default Home