import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import BackgroundCircle from "../elements/BackgroundCircle";
import '../../assets/scss/core/elements/card-move.scss'
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from 'react-number-format';

import TextField from '@material-ui/core/TextField';
import SectionHeader from "./partials/SectionHeader";
import TypingTextCalculate from "../elements/typeing/TypingTextCalculte";

const useStyles = makeStyles((theme) => ({
    root: {

        '& > * + *': {
            marginTop: theme.spacing(2),
            backgroundColor: '#FFC355'

        },
        padding: theme.spacing(10),
        // color:'FFC355'
    },
    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& p':{
            fontSize: 8,
        },
    }

}));

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}

const initValues = {
    'power':'',
    'priceOf':'',
    'yearProduct':'',
    'savingYear':'',
    'backBuyDate':'',
    'priceOfAmd': '',
    'saveYearAmd': ''
}

const Calculate = ({
   texts,
   checked,
   className,
   topOuterDivider,
   bottomOuterDivider,
   topDivider,
   bottomDivider,
   hasBgColor,
   invertColor,
   pushLeft,
   ...props
    }) => {
    const outerClasses = classNames(
        'm-display',
        'bgCalc',
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(

        'reveal-from-bottom',
        'features-tiles-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );


const [start, setStart ] = useState(false)
const [ firstClick, setFirstClick ] = useState(false)
const [ price, setPrice ] = useState(0)
const [ priceX, setPriceX ] = useState(0)
const [ priceY, setPriceY ] = useState(0)
const [ourPrice, setOurPrice] = useState(initValues)
const [err , setErr] = useState(false)
const [errTwo , setErrTwo] = useState(false)
const [errThree , setErrThree] = useState(false)
const [inputWrite, setInputWrite] = useState('p1')
const getInputValue = (event) => {
    setInputWrite('p2')
    setErr(false)
    setErrTwo(false)
    setErrThree(false)
    if(event.target.value === '')  setOurPrice(initValues)
    setPrice(event.target.value)
    }

const getInputValueX = (event) => {
    setInputWrite('p1')
    setErr(false)
    setErrTwo(false)
    setErrThree(false)
    if(event.target.value === '') {
        setOurPrice(initValues)
    }
    setPriceX(event.target.value)
}

const getInputValueY = (event) => {
    setInputWrite('p3')
    setErr(false)
    setErrTwo(false)
    setErrThree(false)
    if(event.target.value === '')  setOurPrice(initValues)
    setPriceY(event.target.value)
}

const checkLanguage = () => {
        if(checked === 1) {
            return {
                title: texts.arm.headerNavigation.CALCULATOR
            }
        }
        if(checked === 2) {
            return {
                title: texts.rus.headerNavigation.CALCULATOR
            }
        }
        if(checked === 3) {
            return {
                title: texts.eng.headerNavigation.CALCULATOR
            }
        }
}
const checkLanguageCalc = () => {
        if(checked === 1) return texts.arm.calculator
        if(checked === 2) return texts.rus.calculator
        if(checked === 3) return texts.eng.calculator
    }
const checkLangError = (input) => {
    if(input === 'p1') {
        if(checked === 1 ) return 'նվազագույն գումար` 50.000֏'
        if(checked === 2 ) return 'минимальная сумма 50.000 AMD'
        if(checked === 3 ) return 'minimum amount 50.000 AMD'
    }
    if(input === 'p2') {
        if(checked === 1 ) return 'նվազագույն  1000կՎտ/ժ'
        if(checked === 2 ) return 'минимальная 1000кВтч'
        if(checked === 3 ) return 'minimum amount 1000kWh'
    }
    if(input === 'p3') {
        if(checked === 1 ) return 'նվազագույն հզորություն 1կՎտ/ժ'
        if(checked === 2 ) return 'Минимальная мощность 1кВтч'
        if(checked === 3 ) return 'Minimum power 1kWh'
    }

 }


const calcPrice = () => {

    if(!firstClick) {
        setFirstClick(true)
        setStart(true)
        setOurPrice(initValues)
        return
    }
    if(priceX < 50000 && inputWrite === 'p1') {
            setOurPrice(initValues)
            setErr(true)
            setErrTwo(true)
            setErrThree(true)
            return;
    }
    if(price < 1000 && inputWrite === 'p2') {
            setOurPrice(initValues)
            setErrTwo(true)
            setErrTwo(true)
            setErr(true)
            return;
    }
    if(priceY < 1 && inputWrite === 'p3') {
           setOurPrice(initValues)
           setErrThree(true)
           setErrTwo(true)
           setErr(true)
        return;
    }

    setFirstClick(true)
    setStart(false)
    setTimeout(() => {
        setStart(true)
    },Math.floor(Math.random() * 1500))


    setErr(false)
    const priceOne = 726
    const priceTwo = 1130

    const powerX = +((priceX/45)/1500).toFixed()
    const priceOfX = powerX * ( powerX <= 10 ? priceTwo : priceOne)
    const priceOfAmdX = (priceOfX*486.5).toFixed()
    const yearProductX = powerX*1550
    const savingYearX = yearProductX*0.09
    const backBuyDateX = +(priceOfX/savingYearX).toFixed()

    const powerY = +priceY
    const priceOfY = powerY * ( powerY <= 10 ? priceTwo : priceOne)
    const priceOfAmdY = (priceOfY*486.5).toFixed()
    const yearProductY = powerY*1550
    const savingYearY = yearProductY*0.09
    const backBuyDateY = +(priceOfY/savingYearY).toFixed()


    const power = +(price/1500).toFixed()

    const priceOf = power * ( power <= 10 ? priceTwo : priceOne)
    const yearProduct = power*1550
    const savingYear = yearProduct*0.09
    const backBuyDate = +(priceOf/savingYear).toFixed()

    if(price.length) {
        setOurPrice({
            power,
            priceOf,
            yearProduct,
            savingYear,
            backBuyDate,
            priceOfAmd: (priceOf*486.5).toFixed(),
            saveYearAmd:( savingYear*486.5).toFixed()

        })

    }
    else if(priceY.length) {
        setOurPrice({
            power:powerY,
            priceOf:  priceOfY,
            yearProduct: yearProductY,
            savingYear:savingYearY,
            backBuyDate:backBuyDateY,
            priceOfAmd: priceOfAmdY,
            saveYearAmd:( savingYearY*486.5).toFixed()

        })
    }

    else {
        setOurPrice({
            power:powerX,
            priceOf:  priceOfX,
            yearProduct: yearProductX,
            savingYear:savingYearX,
            backBuyDate:backBuyDateX,
            priceOfAmd: priceOfAmdX,
            saveYearAmd:( savingYearX*486.5).toFixed()

        })

    }


}


    const [priceValue, setAge] = useState( ' $');
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const classes = useStyles();
    const ref = useRef()

    useEffect(() => {
        if(ref.current){
            ref.current.click()
        }
    }, [])

    const langStyleTitle = () => {
        if(checked === 1) return 'we-arm'
        if(checked === 2) return 'we-next'
        if(checked === 3) return 'we-next'
    }
    return (


        <section
            id='calc'
            style={{position:'relative',paddingTop: 80}}
            {...props}
            className={outerClasses}

        >
            <div style={{position:"absolute",bottom:-30,zIndex:100}}>
                <div id='hexagon-first-one'/>
                <div style={{position:'relative'}}>
                    <img alt='bolt' className='hexCircle rotating' src={require('./../../assets/images/flags/bolt1.png').default}/>
                    <img alt='bolt' className='hexCircleMiddle rotatingBack' src={require('./../../assets/images/flags/bolt2.png').default}/>
                    <img alt='bolt' className='hexCircleDown rotating2' src={require('./../../assets/images/flags/bolt3.png').default}/>
                </div>
            </div>

            <img
                src={require('./../../assets/images/calcHand.png').default}
                alt='calc'
                style={{position:"absolute",width:345, bottom:42,right:-14,opacity: 0.8}}
            />
            <BackgroundCircle next={true} calc={true}/>

            <div className="container" style={{position:'relative'}}>
                <div className={innerClasses} >
                    <SectionHeader makeStyle={langStyleTitle(checked)} data={checkLanguage()}  className="center-content " textWide={true}  right={true} />
                    <TypingTextCalculate service={true} checked={checked} inWork/>
                    <p>{checkLanguageCalc(checked).title}</p>
                    <div className="reveal-from-bottom calc-m" data-reveal-delay="400" style={{display:"flex"}}>

                        <div style={{display:"flex", flexDirection:"column", width: 'fit-content' }}className='m-auto' >
                            <div style={price.length > 0 ||priceY.length > 0  ?{border:'solid 1px gray',borderRadius:4,padding:14,width: 270} : {border:'solid 1px gray',borderRadius:4,padding:14,width: 270}} className='fs-v'>
                            <div style={price.length > 0 ||priceY.length > 0  ?{display:'none'} : {display:"flex"}} className='fadeInDown animated'>
                                <TextField
                                    error={err}
                                    className={classes.input}
                                    style={ {marginBottom:35}}
                                    type='number'
                                    onChange={getInputValueX}
                                    id="outlined-basic"
                                    label={  checkLanguageCalc(checked).optionLeftSide.calcWithMoney}
                                    variant="outlined"
                                    helperText={err ? checkLangError('p1') : ''}
                                />

                            </div>
                            <div
                                style={priceX.length > 0 || priceY.length > 0  ?{display:'none'} : {display:"flex"}}
                                className='fadeIn  animatedd'>
                            <TextField
                                error={errTwo}
                                className={classes.input}
                                type='number'
                                onChange={getInputValue}
                                id="outlined-basic"
                                label={checkLanguageCalc(checked).optionLeftSide.calcIn_kWh}
                                variant="outlined"
                                defaultValue={''}
                                helperText={errTwo ? checkLangError('p2') : ''}
                             />
                            </div>
                            </div>
                            <div
                                style={priceX.length > 0 ||price.length > 0 ?{display:'none'} : {display:"flex",border:'solid 1px gray', marginTop:15, padding:14, borderRadius:4,width: 270}}
                                className='fadeIn animatedd fs-v'
                            >
                                <TextField
                                    error={errThree}
                                    className={classes.input}
                                    type='number'
                                    onChange={getInputValueY}
                                    id="outlined-basic"
                                    label={ checkLanguageCalc().optionLeftSide.powerCalc}
                                    helperText={errTwo ? checkLangError('p3') : ''}
                                    variant="outlined"
                                    defaultValue={''}
                                />
                            </div>
                            <div style={{margin:'28px 0 0 -10px'}}>
                            <Button
                                style={{height: 'fit-content',margin:'-4px 14px 0px 48px'}}
                                onClick={calcPrice}
                                variant="contained"
                                color="primary"
                                ref={ref}
                                className=' btn-calc'
                            >
                                {checkLanguageCalc(checked).optionLeftSide.bottomText}
                            </Button>
                            <FormControl variant="outlined" >
                                <Select
                                    style={{fontFamily: 'FontAwesome'}}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={priceValue}
                                    onChange={handleChange}
                                    className={'select-s'}
                                >
                                    <MenuItem  value={' $'}>$</MenuItem>
                                    <MenuItem value={'֏'}>֏</MenuItem>
                                </Select>
                            </FormControl>
                            </div>
                        </div>

                        <div className={classes.root } style={ !start && firstClick ? {width: '70%'}: {display: 'none'}}>
                            <LinearProgress />
                            <LinearProgress  />
                        </div>
                        <div className={`calculator_each bounceInRight animated`} style={start ?{display:'revert'}: {display:'none'} }>
                            <ul className={` ${ourPrice.power === '' ? 'pol-a ul-m' : 'ul-m'} `} style={{width:'50vw', marginLeft:50}}>
                                <li><p><span>{checkLanguageCalc(checked).optionRightSide.systemPower}</span></p><strong>{ourPrice.power} { ourPrice.power === '' ? null : checkLanguageCalc(checked).optionDetails.kW}</strong> </li>
                                <li><p><span>{checkLanguageCalc(checked).optionRightSide.systemCost}</span></p><strong><NumberFormat value={priceValue ===' $' ? ourPrice.priceOf: ourPrice.priceOfAmd} displayType={'text'} thousandSeparator={true}/>  { ourPrice.priceOf === '' ? null : priceValue === ' $' ? ' $': ' ֏'}</strong></li>
                                <li ><p><span className='m-st '>{checkLanguageCalc(checked).optionRightSide.annualSysProd}</span></p>< strong className='m-gx m-gxt'><NumberFormat className='m-ga' value={ourPrice.yearProduct} displayType={'text'} thousandSeparator={true}/> { ourPrice.yearProduct === '' ? null : checkLanguageCalc(checked).optionDetails.kWh} </strong></li>
                                <li><p><span>{checkLanguageCalc(checked).optionRightSide.annualSavings}</span></p><strong><NumberFormat value={priceValue ===' $' ? ourPrice.savingYear: ourPrice.saveYearAmd} displayType={'text'} thousandSeparator={true}/>{ ourPrice.savingYear === '' ? null : priceValue === ' $' ? ' $': ' ֏'}</strong></li>
                                <li><p><span>{checkLanguageCalc(checked).optionRightSide.redemptionPeriod}</span></p><strong>{ourPrice.backBuyDate ? ourPrice.backBuyDate : null} {ourPrice.backBuyDate === '' ? null :  checkLanguageCalc(checked).optionDetails.year}</strong></li>
                            </ul>

                        </div>
                    </div>

                </div>
            </div>

        </section>
    );
}

Calculate.propTypes = propTypes;
Calculate.defaultProps = defaultProps;

export default Calculate;