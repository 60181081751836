import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link as ScrollLink } from 'react-scroll';
import '../../assets/scss/core/elements/button_c.scss'
import LanguageSelect from "../language/LanguageSelect";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}



const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}

const Header = ({
                  texts,
                  selecting,
                  setSelecting,
                  className,
                  navPosition,
                  hideNav,
                  hideSignin,
                  bottomOuterDivider,
                  bottomDivider,
                  ...props
                }) => {

  const [isActive, setIsactive] = useState(false);
  const [style1, setStyle1] = useState('d-none')
  const [style3, setStyle3] = useState('d-none')
  const [style5, setStyle5] = useState('d-none')
  const [style6, setStyle6] = useState('d-none')
  const [style7, setStyle7] = useState('d-none')
  const nav = useRef(null);
  const hamburger = useRef(null);
  useEffect(() => {
    setTimeout(() => { setStyle1('fadeInDown animated')},4500)
    setTimeout(() => { setStyle3('fadeInDown animated')},5500)
    setTimeout(() => { setStyle5('fadeInDown animated')},6500)
    setTimeout(() => { setStyle6('fadeInDown animated')},7000)
    setTimeout(() => { setStyle7('fadeInDown animated')},7600)
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }

  const classes = classNames(

      'site-header',
      bottomOuterDivider && 'has-bottom-divider',
      className
  );



  // @ts-ignore
  return (
      <header
          {...props}
          className={classes}
      >
        <div className="container " style={{maxWidth: 1600}}>
          <div className={
            classNames(
                'site-header-inner',
                bottomDivider && 'has-bottom-divider'
            )}>
            <div>
              <LanguageSelect
                  selecting={selecting}
                  setSelecting={setSelecting}
              />
            </div>
            {!hideNav &&
            <>
              <button
                  ref={hamburger}
                  className="header-nav-toggle"
                  onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                  ref={nav}
                  className={
                    classNames(
                        'header-nav',
                        isActive && 'is-active'
                    )}>
                <div className="header-nav-inner">

                  <ul className={
                    classNames(
                        'list-reset text-xs',
                        navPosition && `header-nav-${navPosition}`
                    )}>
                    <li>
                      <ScrollLink to="service" spy={true} smooth={true} onClick={closeMenu}>
                        <button className={`btn draw-border ${style1}`}>
                          {selecting === 1 ? texts.arm.headerNavigation.SERVICES : null}
                          {selecting === 2 ? texts.rus.headerNavigation.SERVICES : null}
                          {selecting === 3 ? texts.eng.headerNavigation.SERVICES : null}
                        </button>
                      </ScrollLink>

                    </li>
                  </ul>
                  <ul className={
                    classNames(
                        'list-reset text-xs',
                        navPosition && `header-nav-${navPosition}`
                    )}>
                    <li>
                      <ScrollLink
                          to="friends"
                          spy={true} smooth={true}
                          onClick={closeMenu}><button
                          className={`btn draw-border ${style3}`}>
                        {selecting === 1 ? texts.arm.headerNavigation.PARTNERS : null}
                        {selecting === 2 ? texts.rus.headerNavigation.PARTNERS : null}
                        {selecting === 3 ? texts.eng.headerNavigation.PARTNERS : null}
                      </button></ScrollLink>

                    </li>
                  </ul>
                  <ul className={
                    classNames(
                        'list-reset text-xs',
                        navPosition && `header-nav-${navPosition}`
                    )}>
                    <li>
                      <ScrollLink
                          to="calc"
                          spy={true}
                          smooth={true}
                          onClick={closeMenu}><button
                          className={`btn draw-border ${style5}`}>
                        {selecting === 1 ? texts.arm.headerNavigation.CALCULATOR :null}
                        {selecting === 2 ? texts.rus.headerNavigation.CALCULATOR : null}
                        {selecting === 3 ? texts.eng.headerNavigation.CALCULATOR : null}
                      </button></ScrollLink>

                    </li>
                  </ul>
                  <ul className={
                    classNames(
                        'list-reset text-xs',
                        navPosition && `header-nav-${navPosition}`
                    )}>
                    <li>
                      <ScrollLink
                          to="about"
                          spy={true} smooth={true}
                          onClick={closeMenu}><button
                          className={`btn draw-border ${style6}`}>
                        {selecting === 1 ? texts.arm.headerNavigation.ABOUT :null}
                        {selecting === 2 ? texts.rus.headerNavigation.ABOUT : null}
                        {selecting === 3 ? texts.eng.headerNavigation.ABOUT : null}
                      </button></ScrollLink>
                    </li>
                  </ul>
                  <ul className={
                    classNames(
                        'list-reset text-xs',
                        navPosition && `header-nav-${navPosition}`
                    )}>
                    <li>
                      <ScrollLink
                          to="contact"
                          spy={true}
                          smooth={true}
                          onClick={closeMenu}><button
                          className={`btn draw-border ${style7}`}>
                        {selecting === 1 ? texts.arm.headerNavigation.CONTACTS :null}
                        {selecting === 2 ? texts.rus.headerNavigation.CONTACTS : null}
                        {selecting === 3 ? texts.eng.headerNavigation.CONTACTS : null}
                      </button></ScrollLink>
                    </li>
                  </ul>
                </div>
              </nav>
            </>}
          </div>
        </div>
      </header>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
