import React, {lazy, useCallback, useEffect, useState ,Suspense} from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../../utils/SectionProps';
import SectionHeader from '../partials/SectionHeader';
import '../../../assets/scss/core/elements/typingSecound.css'
import '../../../assets/scss/core/elements/typingFirst.css'
import '../../../assets/scss/core/elements/typingThird.css'
import '../hero.scss'
import OurTeam from "./OurTeam";
import OurWorkers from "./OurWorkers";
import BackgroundCircle from "../../elements/BackgroundCircle";
import TypingTextAboutUs from "../../elements/typeing/TypingTextAboutUs";
import {makeStyles} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import pMinDelay from "p-min-delay";

const WhyChooseUs = lazy(() => pMinDelay(import('./WhyChooseUs'),850))
const GoogleMapForJobs = lazy(() => pMinDelay(import('../GoogleComponent/GoogleMapForJobs'),1500))


const propTypes = {
  ...SectionSplitProps.types
}
const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
      backgroundColor: '#FFC355'

    },
    padding: '60px 20px 0 20px',
  }
}));

const defaultProps = {
  ...SectionSplitProps.defaults
}

const OboutUs = ({
                         checked,
                         texts,
                         className,
                         topOuterDivider,
                         bottomOuterDivider,
                         topDivider,
                         bottomDivider,
                         hasBgColor,
                         invertColor,
                         invertMobile,
                         invertDesktop,
                         alignTop,
                         imageFill,
                         ...props
                       }) => {

  const outerClasses = classNames(
      'bgCont',
      'features-split section',
      topOuterDivider ,
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
  );
  const classes = useStyles();
  const innerClasses = classNames(

      'features-split-inner section-inner',
      topDivider ,
      bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(

      'split-wrap',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
  );

  // «Դդմասար 1», «Պարտիզակ 1», «Պարտիզակ 2». սրանք այն խոշոր նախագծերն են, որոնք իրականացրել է Power Energy-ին Թալինում:
  const img = require('../../../assets/images/storyPic.png').default
  const imgChoose = require('../../../assets/images/chooseUs.jpg').default


  const checkLanguage = () => {
    if(checked === 1) {
      return {
        title: texts.arm.headerNavigation.ABOUT
      }
    }
    if(checked === 2) {
      return {
        title: texts.rus.headerNavigation.ABOUT
      }
    }
    if(checked === 3) {
      return {
        title: texts.eng.headerNavigation.ABOUT
      }
    }
  }

  const checkLanguageForNav = useCallback(() => {
    if(checked === 1) {
      return [
        {
          id: 1,
          name: 'Մեր Աշխատանքները',
          active: true
        },
        {
          id: 2,
          name: 'Մեր Մասնագետներն',
          active: false
        },
        {
          id: 3,
          name: 'Մեր Պատմությունը',
          active: false
        },
        {
          id: 4,
          name: 'Ինչու՞  Ընտրել մեզ',
          active: false
        },
      ]
    }
    if(checked === 2) {
      return [
        {
          id:1,
          name:'Наши работы',
          active: true
        },
        {
          id:2,
          name:'Наши специалисты',
          active: false
        },
        {
          id:3,
          name:`Наша \t история`,
          active: false
        },
        {
          id:4,
          name:'Почему выбрать нас?',
          active: false
        },
      ]
    }
    if(checked === 3) {
      return [
        {
          id:1,
          name:'Our Works',
          active: true
        },
        {
          id:2,
          name:'Our Specialists',
          active: false
        },
        {
          id:3,
          name:'Our History',
          active: false
        },
        {
          id:4,
          name:`Why Choose Us?`,
          active: false
        },
      ]
    }
  },[checked])

  const makeStyleCss = () => {
    if(checked === 3) return 'nav-s-eng'
    if(checked === 1) return 'nav-s-arm'
    if(checked === 2) return 'nav-s-rus'
  }

  const langStyleTitle = () => {
    if(checked === 1) return 'we-arm'
    if(checked === 2) return 'we-next'
    if(checked === 3) return 'we-next'
  }

  useEffect(() => {
    setNavBarState( checkLanguageForNav)
    setNavBarPage(1)

  },[checkLanguageForNav, checked])

  const [navBarState, setNavBarState] = useState(checkLanguageForNav())
  const [navBarPage, setNavBarPage] = useState(3)
  const navBarClick = (num) => {
    let newArr = [...navBarState]
    newArr.forEach((item,x,y) => {
      item.active = false
      if(item.id === num) {
        return [item.active = true]
      }
    })
    setNavBarState(newArr)
    setNavBarPage(num)
  }

  useEffect(() => {
    setNavBarPage(1)
  },[])

  return (
      <section
          id='about'
          {...props}
          className={outerClasses}
          style={{height: 950,paddingBottom:100}}
      >

        <div style={{position:"absolute",bottom:-30,zIndex:100}}>
          <div id='hexagon-first-one'/>
          <div style={{position:'relative'}}>
            <img alt='bolt' className='hexCircle rotating' src={require('../../../assets/images/flags/bolt1.png').default}/>
            <img alt='bolt' className='hexCircleMiddle rotatingBack' src={require('../../../assets/images/flags/bolt2.png').default}/>
            <img alt='bolt' className='hexCircleDown rotating2' src={require('../../../assets/images/flags/bolt3.png').default}/>
          </div>
        </div>
        <div style={{position:"absolute",bottom:-27,right:-125,zIndex:100}}>
          <div  id='hexagon-first-two'/>
          <div style={{position:'relative'}}>
            <img alt='bolt' className='hexRight rotating' src={require('../../../assets/images/flags/bolt1.png').default}/>
            <img alt='bolt' className='hexRightMiddle rotatingBack' src={require('../../../assets/images/flags/bolt2.png').default}/>
            <img alt='bolt' className='hexRightDown rotating2' src={require('../../../assets/images/flags/bolt3.png').default}/>
          </div>
        </div>
        <img src={require('../../../assets/images/solarR.png').default} style={{position:"absolute",width:380,left:'-150px',bottom:-50}} alt='solar'/>
        <div className="container ">
          <BackgroundCircle next={true}/>
          <BackgroundCircle next={true} small={true}/>
          <div className={innerClasses} style={{paddingTop:60, paddingBottom:0}}>
            <SectionHeader makeStyle={langStyleTitle(checked)}  data={checkLanguage()} className="center-content"  />
            <TypingTextAboutUs service={true} checked={checked} inWork/>
            <div className={splitClasses}>
              <div className={`kss1 split-item xType  reveal-from-left kss-w`} style={{display:"inherit"}}>
                <div className='navInAbout  reveal-from-left'>
                  <ul className={`tabs nav-c ${makeStyleCss(checked)} isp-s`} id="navigation">
                    {navBarState.map((item) => (
                        <li
                            key={item.id}
                        >
                          <a
                              href="!#"
                              id={item.id}
                              onClick={
                                (e) => {
                                  e.preventDefault();navBarClick(item.id)
                                }}
                              className={item.active ? 'tab active' : 'tab'}
                          >{item.name}
                          </a>
                        </li>
                    ))}
                  </ul>
                </div>

                {
                  navBarPage === 1 ?(
                      <Suspense fallback={
                        <div className={classes.root} style={  {width: '100%', marginTop: 148}}>
                          <LinearProgress />
                          <LinearProgress />
                        </div>
                      }>
                        <GoogleMapForJobs checked={checked} />
                      </Suspense>): null
                }

                {
                  navBarPage === 3 ?  <OurTeam img={img} checked={checked} /> : null
                }
                {
                  navBarPage === 2 ?  <OurWorkers  checked={checked} /> : null
                }
                {
                  navBarPage === 4 ? (
                      <Suspense fallback={
                          <div className={classes.root} style={  {width: '100%', marginTop: 148}}>
                            <LinearProgress />
                            <LinearProgress />
                          </div>
                         }>
                        <WhyChooseUs img={imgChoose} checked={checked} texts={texts}/>
                      </Suspense>): null
                }
              </div>

            </div>
          </div>
        </div>
      </section>
  );
}

OboutUs.propTypes = propTypes;
OboutUs.defaultProps = defaultProps;

export default OboutUs;