import React from 'react';
import './circle.scss'
const BackgroundCircle = ({next, calc, small, part}) => {

    return (
        <div className='arrow-container'>
            {
                next ? (
                    <>
                        <div className="mainOur">
                            <div className={`mainOur__text-wrapper ${calc ? 'mainOur__calc': null} `} style={small ? {top:'21%',left:-189}:null}>

                                {
                                    small ? (
                                        <>

                                        <svg xmlns="http://www.w3.org/2000/svg" style={{overflow:'inherit'}} className={`dotted-circleY`} width="180" height="180"
                                             overflow="visible">
                                            <circle cx="90" cy="90" r="90" fill="none" stroke="#013856" strokeWidth="1"
                                                    strokeMiterlimit="6" strokeDasharray="3.921,4.9271"/>
                                        </svg>
                                        </>
                                    ): (

                                        <svg xmlns="http://www.w3.org/2000/svg" style={{overflow:'inherit'}} className={`${small? 'dotted-circleY': 'dotted-circle'} `} width="200" height="200"
                                             overflow="visible">

                                            <circle cx="100" cy="100" r="100" fill="none" stroke="#013856" strokeWidth="1"
                                                    strokeMiterlimit="6" strokeDasharray="3.921,4.9271"/>
                                        </svg>
                                    )
                                }

                            </div>
                        </div>
                        <div className="main_nextOur">
                        <div className={`main_nextOur__text-wrapper ${calc ? 'mainOur__calcX': null}`} style={small ? {top:'41%',right:-189}:null}>
                            {
                                small ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" style={{overflow:'inherit'}} className={`dotted-circleY`} width="180" height="180"
                                         overflow="visible">
                                        <circle cx="90" cy="90" r="90" fill="none" stroke="#013856" strokeWidth="1"
                                                strokeMiterlimit="6" strokeDasharray="3.921,4.9271"/>
                                    </svg>
                                ): (
                                    <svg xmlns="http://www.w3.org/2000/svg" style={{overflow:'inherit'}} className={`${small? 'dotted-circleY': 'dotted-circle'} `} width="200" height="200"
                                         overflow="visible">
                                        <circle cx="100" cy="100" r="100" fill="none" stroke="#013856" strokeWidth="1"
                                                strokeMiterlimit="6" strokeDasharray="3.921,4.9271"/>
                                    </svg>
                                )
                            }


                        </div>
                    </div>
                    </>
                ) : (
                    <>
                        <div className="arrow arrow--top">
                        <svg xmlns="http://www.w3.org/2000/svg" width="270.11" height="649.9" overflow="visible" >

                        <g className="item-to bounce-1">
                        <path className="geo-arrow draw-in"
                        d="M135.06 142.564L267.995 275.5 135.06 408.434 2.125 275.499z"/>
                        </g>
                        <circle className="geo-arrow item-to bounce-2" cx="194.65" cy="69.54" r="7.96"/>
                        <circle className="geo-arrow draw-in" cx="194.65" cy="39.5" r="7.96"/>
                        <circle className="geo-arrow item-to bounce-3" cx="194.65" cy="9.46" r="7.96"/>
                        <g className="geo-arrow item-to bounce-2">
                        <path className="st0 draw-in" d="M181.21 619.5l13.27 27 13.27-27zM194.48 644.5v-552"/>
                        </g>
                        </svg>
                        </div>
                        <div className="arrow arrow--bottom geo-arrow2" style={{zIndex:2}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="31.35" height="649.9" overflow="visible" style={{overflow:'inherit'}}>

                        <g className="item-to bounce-1">
                        <circle className="geo-arrow item-to bounce-3" cx="15.5" cy="580.36" r="7.96"/>
                        <circle className="geo-arrow draw-in" cx="15.5" cy="610.4" r="7.96"/>
                        <circle className="geo-arrow item-to bounce-2" cx="15.5" cy="640.44" r="7.96"/>
                        <g className="item-to bounce-2">
                        <path className="geo-arrow draw-in" d="M28.94 30.4l-13.26-27-13.27 27zM15.68 5.4v552"/>
                        </g>
                        </g>
                        </svg>
                        </div>
                        <div className="main">
                        <div className="main__text-wrapper">

                        <svg xmlns="http://www.w3.org/2000/svg" style={{overflow:'inherit'}} className="dotted-circle" width="200" height="200"
                        overflow="visible">
                        <circle cx="100" cy="100" r="100" fill="none" stroke="#013856" strokeWidth="1"
                        strokeMiterlimit="6" strokeDasharray="3.921,4.9271"/>
                        </svg>

                        </div>
                        </div>
                        <div className="main_next">
                        <div className="main_next__text-wrapper">

                        <svg xmlns="http://www.w3.org/2000/svg" className="dotted-circle" width="200" height="200"
                        overflow="visible" style={{overflow:'inherit'}}>
                        <circle cx="100" cy="100" r="100" fill="none" stroke="#013856" strokeWidth="1"
                        strokeMiterlimit="6" strokeDasharray="3.921,4.9271"/>
                        </svg>

                        </div>
                        </div>
                        <div className="main_next_next">
                        <div className="main_next_next__text-wrapper mb0c" style={{zIndex:4}}>

                        <svg xmlns="http://www.w3.org/2000/svg" className="dotted-circle" width="900" height="900"
                        overflow="visible" style={{overflow:'inherit'}}>
                        <circle cx="450" cy="450" r="450" fill="none" stroke="#013856" strokeWidth="20" strokeMiterlimit="10" strokeDasharray="1.921,1.9271"/>
                        </svg>

                        </div>
                        </div>
                    </>
                )
            }


        </div>
    );
};

export default BackgroundCircle;