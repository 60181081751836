import React from 'react';
import Typewriter from 'typewriter-effect';

const TypingTextAboutUs = ({checked, inWork, inHide}) =>{



    return (
        <div className={`typing-textH-Our in-wrks d-nonem `} style={{textAlign:"center"}}>
            {
                checked === 1 ? (
                    <Typewriter
                        options={{
                            loop: true,
                        }}
                        onInit={(typewriter) => {

                            typewriter.changeDelay(70)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.8rem; line-height: 13px;">Հայաստանը համարվում է արևի երկիր:`)
                                .changeDelay(50)
                                .pauseFor(8000)
                                .deleteAll(30)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.7rem; line-height: 13px;"> Ստացիր արևից ավելին:</span>`)
                                .pauseFor(8000)
                                .deleteAll(30)

                                .start();
                        }}
                    />
                ) : null
            }
            {
                checked === 3 ? (
                    <Typewriter
                        options={{

                            // autoStart: true,
                            loop: true,
                        }}
                        onInit={(typewriter) => {

                            typewriter.changeDelay(70)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.9rem; line-height: 13px;">Armenia is considered a country of the Sun.`)
                                .changeDelay(50)
                                .pauseFor(8000)
                                .deleteAll(30)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.9rem; line-height: 13px;">Get more from the Sun.</span>`)
                                .pauseFor(8000)
                                .deleteAll(30)

                                .start();
                        }}
                    />
                ) : null
            }
            {
                checked === 2 ? (
                    <Typewriter
                        options={{

                            // autoStart: true,
                            loop: true,
                        }}
                        onInit={(typewriter) => {

                            typewriter.changeDelay(70)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.9rem; line-height: 13px;">Армения считается страной Солнца.`)
                                .changeDelay(50)
                                .pauseFor(8000)
                                .deleteAll(30)
                            typewriter.typeString(`<span style="color: rgba(1, 56, 86, 0.9); font-size: 0.9rem; line-height: 13px;">Получите больше от Солнца.</span>`)
                                .pauseFor(8000)
                                .deleteAll(30)

                                .start();
                        }}
                    />
                ) : null
            }
        </div>
    );
}

export default TypingTextAboutUs
