import React from 'react';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';
// import Image from '../../elements/Image';
import Lighting from "../../elements/Lighting";

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
      'logo-e',
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}

    >

        <Lighting />

    </div>
  );
}

export default Logo;