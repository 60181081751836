import React, {Component} from "react";
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import '../footerL.scss'
export class MapContainer extends Component {

    state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        isOpen:true,
        mapCenter: {
            lat:40.197807,
            Ing: 44.499939,
        }
    };
    // onMarkerClick = (props, marker, e) =>
    //     this.setState({
    //         selectedPlace: props,
    //         activeMarker: marker,
    //         showingInfoWindow: true
    //     });

    // onMapClicked = (props) => {
    //
    //     if (this.state.showingInfoWindow) {
    //         this.setState({
    //             showingInfoWindow: false,
    //             activeMarker: null
    //         })
    //     }
    // };

    onClose = () => {
        this.setState({
            isOpen:!this.state.isOpen,
        })
    };

    langChange = () => {
        if(this.props.checked === 1) return this.props.texts.arm.headerNavigation.CONTACTS
        if(this.props.checked === 3) return this.props.texts.eng.headerNavigation.CONTACTS
        if(this.props.checked === 2) return this.props.texts.rus.headerNavigation.CONTACTS
    };

     langStyleTitle = () => {
        if(this.props.checked === 1) return 'we-arm'
        if(this.props.checked === 2) return 'we-next'
        if(this.props.checked === 3) return 'we-next'
    }

    render() {

        return (
            <div style={{ height:700, width: '100%'}} >
                <div className={ this.state.isOpen ? 'footWrap drawer' : 'footWrap drawer close'} >
                    <h2 style={{textAlign:"center",color:' rgb(1, 56, 86)'} } className={ this.langStyleTitle(this.props.checked)  + ` text-smc`}>{this.langChange()}</h2>
                    <div className='firstFooterCont   fl-col firstFooterCont-mb nonePad'>

                        <img className='imgSun'
                             alt='sun'
                             src={require('../../../assets/images/boo.jpg').default}
                        />
                        <div className='textStyleFooter textStyleFooterMb ty-pas  '>
                            <div className=' textStyleFooterMb goCe sd--d sd--ds' style={{color: '#00536c'}}>
                                <p>Մեր Գրասենյակը գտնվում է ՝</p>
                                <p>ՀՀ, Երևան, 0014, Ավետիսյան 36-60</p>

                                <p><a style={{color:'#FFA600'}} href="tel:+374 11 550 155">(+374) 11 550 155</a></p>
                                <p><a style={{color:'#FFA600'}}  href="mailto:info@powerenergy.am">info@powerenergy.am</a></p>
                            </div>
                        </div>


                    </div>
                    {
                        this.state.isOpen ? (
                            <div className="wrapper">
                                <div onClick={this.onClose}>
                                    <svg width="18px" height="17px" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <g id="prev"
                                           transform="translate(8.500000, 8.500000) scale(-1, 1) translate(-8.500000, -8.500000)">
                                            <polygon className="arrow" points="16.3746667 8.33860465 7.76133333 15.3067621 6.904 14.3175671 14.2906667 8.34246869 6.908 2.42790698 7.76 1.43613596"/>
                                            <polygon className="arrow-fixed"  points="16.3746667 8.33860465 7.76133333 15.3067621 6.904 14.3175671 14.2906667 8.34246869 6.908 2.42790698 7.76 1.43613596"/>
                                            <path d="M-1.48029737e-15,0.56157424 L-1.48029737e-15,16.1929159 L9.708,8.33860465 L-2.66453526e-15,0.56157424 L-1.48029737e-15,0.56157424 Z M1.33333333,3.30246869 L7.62533333,8.34246869 L1.33333333,13.4327013 L1.33333333,3.30246869 L1.33333333,3.30246869 Z"/>
                                        </g>
                                    </svg>

                                </div>
                            </div>
                        ) : (
                            <div className="wrapper">
                                <div onClick={this.onClose}>
                                    <svg width="18px" height="17px" viewBox="-1 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <polygon className="arrow" points="16.3746667 8.33860465 7.76133333 15.3067621 6.904 14.3175671 14.2906667 8.34246869 6.908 2.42790698 7.76 1.43613596"/>
                                            <polygon className="arrow-fixed" points="16.3746667 8.33860465 7.76133333 15.3067621 6.904 14.3175671 14.2906667 8.34246869 6.908 2.42790698 7.76 1.43613596"/>
                                            <path d="M-4.58892184e-16,0.56157424 L-4.58892184e-16,16.1929159 L9.708,8.33860465 L-1.64313008e-15,0.56157424 L-4.58892184e-16,0.56157424 Z M1.33333333,3.30246869 L7.62533333,8.34246869 L1.33333333,13.4327013 L1.33333333,3.30246869 L1.33333333,3.30246869 Z"/>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        )
                    }


                    <div className='firstFooterCont saf-As fl-col firstFooterCont-mb '>
                        <div className=' textStyleFooterMb goCe sd--d sd--ds' style={{color: '#00536c'}}>
                            <p>Մեր Գրասենյակը գտնվում է ՝</p>
                            <p>ՀՀ, Երևան, 0014, Ավետիսյան 36-60</p>

                            <p><a style={{color:'#FFA600'}} href="tel:+374 11 550 155">(+374) 11 550 155</a></p>
                            <p><a style={{color:'#FFA600'}}  href="mailto:info@powerenergy.am">info@powerenergy.am</a></p>
                        </div>


                        <img className='imgSun'
                             alt='sun'
                             src={require('../../../assets/images/boo2.jpg').default}
                        />

                    </div>
                    <p className='pow-fin' style={{textAlign:"center", fontSize:12}}>POWERENERGY © 2021
                    </p>
                </div>
                <Map google={this.props.google}
                     style={{width:"175%"}}
                    // onClick={this.onMapClicked}
                     initialCenter={{
                         lat: this.state.mapCenter.lat,
                         lng: this.state.mapCenter.Ing
                     }}
                     zoom={17}
                     center = {{
                         lat: this.state.mapCenter.lat,
                         lng: this.state.mapCenter.Ing
                     }}

                >
                    <Marker
                        position = {{
                            lat: this.state.mapCenter.lat,
                            lng: this.state.mapCenter.Ing
                        }}
                    />

                </Map>
            </div>

        )
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyALL9olOADhpA5OqHTL4M4B1_3nUaoCK34')
})(MapContainer)