import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';

const LayoutDefault = ({ children,selecting,setSelecting, texts}) => {


    return(

        <>
            <Header
                texts={texts}
                selecting={selecting}
                setSelecting={setSelecting}
                navPosition="right"
                className="reveal-from-bottom"
            />
            <main  className="site-content"  id='back'>
                {children}
            </main>
            <Footer texts={texts}
                    selecting={selecting}/>
        </>
    );
}

export default LayoutDefault;  