import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import firebase from 'firebase/app';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './assets/scss/style.scss';

const history = createBrowserHistory();


// Initialize Firebase
firebase.initializeApp({
    apiKey: "AIzaSyDwpPr0CsllTqnC_0j6zDsFgzsDo5G7p6A",
    authDomain: "power-energy-example.firebaseapp.com",
    projectId: "power-energy-example",
    storageBucket: "power-energy-example.appspot.com",
    messagingSenderId: "640333890475",
    appId: "1:640333890475:web:24a0c3db8f2c540ad588ee",
    measurementId: "G-9EES7SWZN5"
});

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
